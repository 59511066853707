import React, { useEffect, useState } from 'react';
import './Home.css';
import { AppTools, PrepareDeclarationType, Role, getImgByRole } from '../types.ts';
import { WorkOrderCuttedImg, ScanImg } from '../images/index.js';
import Parameter from '../views/Parameter.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { ID_ROUTE, SCAN_WORK_ORDER_ROUTE, UNIQUE_WORK_ORDER_ROUTE, WORK_ORDER_ROUTE } from '../route.ts';
import { useRequest } from '../hooks/index.js';
import { BASE_URL } from '../const.ts';
import { UserInfoResponse } from '../api/responses.ts';
import useSaveNavigationContext, { NavigationElement } from '../contexts/SaveNavigationContext.tsx';

type HomeProps = {
    appTools: AppTools
}

const Home = (props: HomeProps) => {
    const {appTools} = props;
    const [, , get] = useRequest(BASE_URL, appTools);
    const [userInfo, setUserInfo] = useState<UserInfoResponse | undefined>();

    const { getNavigation } = useSaveNavigationContext();
    const navigate = useNavigate();

    useEffect(() => {
        get<UserInfoResponse>("/api/user/getCurrentInfo").then((response) => {
            setUserInfo(response.data);
        }).catch(() => {
        });

        // Si une déclaration est en cours, on redirige vers la page de déclaration
        const prepare = getNavigation<PrepareDeclarationType>(NavigationElement.DeclareHalfDayWorkOrder);
        if(prepare !== undefined){
            navigate(UNIQUE_WORK_ORDER_ROUTE.replace(ID_ROUTE, prepare.workId.toString()), {replace: true});
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className='home-main'>
                {userInfo && (
                    <div className='home-user-section' onClick={() => {
                        appTools.pushDisplay(<Parameter roles={userInfo.userRoleIds.filter(x => x !== Role.Admin)} appTools={props.appTools} hideParameter={() => {appTools.popDisplay()}} />)
                        }}>
                        <div className='home-user-detail'>
                            <p className='home-user-name'>{userInfo.userLabelle}</p>
                            <p className='home-user-info'>{userInfo.company.companyName}</p>
                        </div>
                        <div className='home-user-icon-container'>
                            {userInfo.userRoleIds.map((role) => {
                                return <img key={role} alt='' className='home-user-icon' src={getImgByRole(role)} />
                            })}
                        </div>
                    </div>
                )}
                
                <div className='home-separator' />

                <Link to={WORK_ORDER_ROUTE} className='home-button'>
                    <img className='home-button-img' alt='' src={WorkOrderCuttedImg} />
                    <div className='home-button-fade'/>
                    <p className='home-button-text'>Bons de travaux</p>
                    <p className='home-button-info'></p>
                </Link>

                <Link to={SCAN_WORK_ORDER_ROUTE} className='home-button'>
                    <img className='home-button-img' alt='' src={ScanImg} />
                    <div className='home-button-fade'/>
                    <p className='home-button-text'>Scanner un bon</p>
                    <p className='home-button-info'></p>
                </Link>
            </div>
        </>
    );
}

export default Home;