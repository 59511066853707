import React from "react";
import { createContext, useContext } from "react";

export enum NavigationElement{
    /**
     * L'utilisateur pas encore connecté souhaite déclarer une signature sur un bon
     * de travaux. Cette enregistrement permettra de garder en local les informations de déclaration, 
     * puis, une fois qu'il se connecte, de revenir sur le permis de travail pour commencer
     * la déclaration de la signature.
     */
    DeclareHalfDayWorkOrder = 0,
    /**
     * L'utilisateur souhaite tout de suite être redirigé vers la page de scan de QR Code de 
     * son compte afin de se connecter.
     */
    AutoClickScanButton = 1,
}

export type SaveNavigationContextType = {
    /**
     * Sauvegade un paramètre de navigation
     * @param element La clé de navigation à sauvegarder
     * @param data Les données à sauvegarder
     * @returns 
     */
    saveNavigation: (element: NavigationElement, data: any) => void;
    /**
     * Récupère un paramètre de navigation
     * @param element La clé de navigation à récupérer
     */
    getNavigation: <TResult,> (element: NavigationElement) => TResult | undefined ;
    /**
     * Efface un paramètre de navigation
     * @param element La clé de navigation à effacer
     */
    clearNavigation: (element: NavigationElement) => void;
}

const SaveNavigationContext = createContext<SaveNavigationContextType>({
    saveNavigation: () => {},
    getNavigation: () => undefined,
    clearNavigation: () => {}
});

const useSaveNavigationContext = () => {
    const context = useContext<SaveNavigationContextType>(SaveNavigationContext);
    if(context === undefined){
        throw new Error("SaveNavigationContext must be used within a SaveNavigationContextProvider");
    }
    return context;
}

export default useSaveNavigationContext;

type SaveNavigationContextProviderProps = {
    children: React.ReactNode
}

export const SaveNavigationContextProvider = (props: SaveNavigationContextProviderProps) => {
    const saveNavigation = (element: NavigationElement, data: any) => {
        sessionStorage.setItem(element.toString(), JSON.stringify(data));
    }

    const getNavigation = <TResult,> (element: NavigationElement): TResult | undefined => {
        const data = sessionStorage.getItem(element.toString());
        if(data){
            return JSON.parse(data) as TResult;
        }else{
            return undefined;
        }
    }

    const clearNavigation = (element: NavigationElement) => {
        sessionStorage.removeItem(element.toString());
    }

    return (
        <SaveNavigationContext.Provider value={{saveNavigation, getNavigation, clearNavigation}}>
            {props.children}
        </SaveNavigationContext.Provider>
    );
}