import React, { createRef, useEffect, useState } from "react";
import './PdfReader.css';
import { pdfjs, Document, Page } from 'react-pdf';
import { GoBack } from "..";
import { AppTools, MessageViewType } from "../../types.ts";
import { useRequest } from "../../hooks/index.js";
import { BASE_URL } from "../../const.ts";

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { GoBackImg, GoFrontmg, ZoomInImg, ZoomOutImg } from "../../images/index.js";

type PdfReaderProps = {	
    fileName: string,
    onClose: () => void,
    appTools: AppTools,
    customToken?: string,
    getPdfFromPublicRoute?: boolean
}

const PdfReader = (props: PdfReaderProps) => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    const mainRef = createRef<HTMLDivElement>();
    const gobackRef = createRef<HTMLImageElement>();
    const gofrontRef = createRef<HTMLImageElement>();
    const { fileName, onClose, appTools, customToken, getPdfFromPublicRoute = false} = props;

    const [pdfString, setPdfString] = useState<string | undefined>();
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageScale, setPageScale] = useState<number>(1);

    const [, , get] = useRequest(BASE_URL, appTools, customToken);

    const triggerHideReader = () => {
        mainRef.current!.style.animationName = "slideout";
        setTimeout(() => {
            if(mainRef.current){
                mainRef.current!.style.opacity = "0";
            }
            onClose();
        },400);
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
      };

    useEffect(() => {
        const url = getPdfFromPublicRoute ? "/api/public/workOrders/pdfAsBase64/" : "/workOrders/pdfAsBase64/";

        get<string>(url + fileName).then((res) => {
            setPdfString(res.data);
        }).catch((err) => {
            props.appTools.showMessage(MessageViewType.Error, "Erreur lors du chargement du fichier", err.message);
            triggerHideReader();
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(numPages){
            if(pageNumber === 1){
                gobackRef.current!.style.opacity = "0.3";
            }else{
                gobackRef.current!.style.opacity = "1";
            }
            if(pageNumber === numPages){
                gofrontRef.current!.style.opacity = "0.3";
            }else{
                gofrontRef.current!.style.opacity = "1";
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, numPages])

    const changePage = (newPageNumber) => {
        if(newPageNumber < 1 || newPageNumber > numPages!){
            return;
        }
        setPageNumber(newPageNumber);
    }

    return(
        <div ref={mainRef} className="pdfReader-main">
            <GoBack onGoBack={triggerHideReader} />
            {pdfString && (
                <div className="pdfReader-container">
                    <Document file={`data:application/pdf;base64,${pdfString}`} onLoadSuccess={onDocumentLoadSuccess} >
                        <Page scale={pageScale} className="pdfReader-page" canvasBackground="#EFEFEF" pageNumber={pageNumber}/>
                    </Document>
                </div>
            )}
            {numPages && (
                <div className="pdfReader-bottom">
                    <img alt="zoom out" src={ZoomOutImg} onClick={() => {setPageScale(pageScale - 0.1)}}/>
                    <img alt="go back" ref={gobackRef} src={GoBackImg} onClick={() => {changePage(pageNumber - 1)}}/>
                    <span>{pageNumber} / {numPages}</span>
                    <img alt="go front" ref={gofrontRef} src={GoFrontmg} onClick={() => {changePage(pageNumber + 1)}}/>
                    <img alt="zoom in" src={ZoomInImg} onClick={() => {setPageScale(pageScale + 0.1)}}/>
                </div>
            )}
        </div>
    )
}

export default PdfReader;