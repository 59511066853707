import React, { useEffect, useState } from 'react';
import './WorkOrderDetailReadOnly.css';
import { AppTools, MessageViewType } from '../../types.ts';
import { useRequest } from '../../hooks';
import { BASE_URL } from '../../const.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, WorkOrderDetailReponse } from '../../api/responses';
import { SCAN_WORK_ORDER_ROUTE, WORK_ORDER_ROUTE } from '../../route.ts';
import { ConsultWorkOrderDetail } from '../../views';

type WorkOrderDetailReadOnlyProps = {
    appTools: AppTools
}

const WorkOrderDetailReadOnly = (props: WorkOrderDetailReadOnlyProps) => {
    const navigate = useNavigate();
    const { idOrQrCodeValue } = useParams();
    const { appTools } = props;
    const [, , get] = useRequest(BASE_URL, props.appTools);

    const [model, setModel] = useState<WorkOrderDetailReponse | undefined>();

    useEffect(() => {
            // on vérifie ce qui est passé en paramètre
            const id: number = Number(idOrQrCodeValue);
            if(!isNaN(id)){
                // si c'est un nombre, alors cela signifie que c'est un id de bon
                loadWorkOrder(id);
            }else{
                // si ce n'est pas un nombre, alors cela signifie que c'est une valeur
                // de QRCode
                get<WorkOrderDetailReponse>("/signature/workOrder/getByQrCode/" + idOrQrCodeValue).then((responses) => {
                    setModel(responses.data);
                }).catch((err: ApiError) => {
                    if(err.code === 404){
                        props.appTools.showMessage(MessageViewType.NotFound, err.message);
                    }else{
                        props.appTools.showMessage(MessageViewType.Error, err.message);
                    }
                    // on revient sur la route du scanner, puisque c'est le seul passage
                    // qui donne un qrCode au lieu d'un id de bon
                    navigate(SCAN_WORK_ORDER_ROUTE);
                });
            }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const loadWorkOrder = (id: number) => {
        get<WorkOrderDetailReponse>("/api/public/workOrders/" + id).then((responses) => {
            setModel(responses.data);
        }).catch((err: ApiError) => {
            console.log(err);
            if(err.code === 404){
                props.appTools.showMessage(MessageViewType.NotFound, err.message);
            }else{
                props.appTools.showMessage(MessageViewType.Error, err.message);
            }
            navigate(WORK_ORDER_ROUTE);
        });
    }

    return (
        <>
            {model && (
                <ConsultWorkOrderDetail 
                    workOrder={model}
                    userInfo={undefined}
                    appTools={appTools}
                    onValidateSign={() => {
                        props.appTools.showMessage(MessageViewType.Success, "Signature validée");
                        navigate("/");
                    }}
                    onFailToLoad={() => {}}
                    onGoBack={() => navigate(WORK_ORDER_ROUTE)}
                    />
            )}
        </>
    );
}

export default WorkOrderDetailReadOnly;