import React, {useEffect, useState} from "react";
import './Login.css';
import { PetraSignLogoImg, QrCodeImg } from "../../images";
import { QRCodeReader } from "../../views";
import { AppTools } from "../../types.ts";
import { ATTEMPT_CONNECT_ROUTE, tryGetValueOfQrCode, WORK_ORDER_ROUTE } from "../../route.ts";
import config from "../../config.ts";
import useSaveNavigationContext, { NavigationElement } from "../../contexts/SaveNavigationContext.tsx";

type LoginProps = {
    isExistUserId: (userId: string) => Promise<boolean>,
    appTools: AppTools
}

const Login = (props : LoginProps) => {

    const { isExistUserId, appTools } = props;

    const [readQRCode, setReadQRCode] = useState<boolean>(false);
    const { getNavigation, clearNavigation } = useSaveNavigationContext();

    const onQRCodeRead = (text: string) => {
        text = tryGetValueOfQrCode(text);
        setReadQRCode(false);
        if(text.includes(WORK_ORDER_ROUTE)){
            window.location.href = text;
        }else{
            appTools.isLoading(true);
            isExistUserId(text)
            .then((result) => {
                // il n'y a rien à faire, la fonction 'isExistUserId'
                // va changer l'état de connexion et App.tsx se charge de 
                // changer de page 
            })
            .catch((error) => {

            })
            .finally(() => {
                appTools.isLoading(false);
            })
        }
    };

    useEffect(() => {
        // vérifie si l'url contient un 'attempt-connect', cela signifie
        // que l'utilisateur à scanner son QRCode utilisateur
        if(window.location.href.includes(ATTEMPT_CONNECT_ROUTE)){
            // on récupère la valeur en fin de route après 'attempt-connect'
            onQRCodeRead(window.location.href);
        }
        
        // Vérifie si l'utilisateur souhaite tout de suite être redirigé vers la page de scan de QR Code de
        // son compte afin de se connecter.
        const autoHit = getNavigation<boolean>(NavigationElement.AutoClickScanButton);
        if(autoHit !== undefined){
            setReadQRCode(true);
            clearNavigation(NavigationElement.AutoClickScanButton);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {!readQRCode && (
                <div className="login-main fill-to-screen">
                    <img alt="logo" src={PetraSignLogoImg} className="login-title" />
                    <p className="login-description">Pour vous authentifier, flasher le QR Code associé à votre profil.</p>
                    <img alt="login" className="login-img" src={QrCodeImg} />
                    <button onClick={() => {setReadQRCode(true)}} className="white-button login-bottom-button">Scanner</button>
                </div>
            )}
            {readQRCode && (
                <QRCodeReader 
                    valueToIncludeToValidateQrCode={config.appNameDomain}
                    description="Flasher le QR Code associé à votre profil" 
                    handleQrCodeRead={onQRCodeRead}
                    onGoBack={() => setReadQRCode(false)} />
            )}
        </>
    );
}

export default Login;