import React, { CSSProperties, useEffect, useState } from 'react';
import './WOElement.css';
import { Link } from 'react-router-dom';
import { WorkOrderResponse } from '../api/responses.ts';
import { Role, WorkOrderState, getDescriptionStatus } from '../types.ts';
import { ID_ROUTE, UNIQUE_WORK_ORDER_ROUTE } from '../route.ts';
import { CheckGreenImg, EntryToSignWhiteImg, ExitToSignWhiteImg, ExitToSignWorkToBeApprovedPurpleBlueImg, SignatureWhiteImg, UnfinishedWorkYellowImg, WaitingImg, WipWhiteImg, WorkToBeApprovedPurpleImg } from '../images/index.js';
import { BLACK, BLUE_PRIMARY, BLUE_SECONDARY, GREEN_PRIMARY, PURPLE_BLUE_PRIMARY, PURPLE_PRIMARY, PURPLE_SECONDARY, WHITE, YELLOW_PRIMARY, YELLOW_SECONDARY } from '../const.ts';

type WOElementProps = {
    userRole: Role,
    workOrder: WorkOrderResponse
};

const WOElement = (props: WOElementProps) => {
    const {userRole, workOrder} = props;
    const route = UNIQUE_WORK_ORDER_ROUTE.replace(ID_ROUTE, workOrder.workId.toString());
    const [descStatus, setDescStatus] = useState<string>(getDescriptionStatus(workOrder.workState));
    const [sourceImg, setSourceImg] = useState<string>("");
    const [containerCss, setContainerCss] = useState<CSSProperties>();
    const [containerImgCss, setContainerImgCss] = useState<CSSProperties>();

    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [number, setNumber] = useState<string>("");

    useEffect(() => {
        if(workOrder.workOrderOT || workOrder.workOrderOP){
            if(workOrder.workOrderOP?.includes(':')){
                const op = workOrder.workOrderOP.split(':')[0];
                setNumber(workOrder.workOrderOT + " : " + op);
            }else{
                setNumber(workOrder.workOrderOT+ "");
            }
        }else{
            setNumber(workOrder.workId.toString())
        }

        if(workOrder.workOrderTechnicalPost){
            setTitle(workOrder.workOrderTechnicalPost);
            setDescription(workOrder.workLabelle);
        }else{
            setTitle(workOrder.workLabelle);
            setDescription("Statut : "+getDescriptionStatus(workOrder.workState));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [descStatus])

    useEffect(() => {
        let optionalSign :boolean = false;
        if(workOrder.workState === WorkOrderState.WIP && workOrder.canInteractRoles)
        {
            optionalSign = workOrder.canInteractRoles.includes(userRole);
        }

        if(optionalSign){
            setSourceImg(SignatureWhiteImg);
            setDescStatus("Signature optionnel");
            setContainerCss({backgroundColor: BLUE_PRIMARY, color: WHITE});
            setContainerImgCss({backgroundColor: BLUE_SECONDARY});
        }else{
            switch(workOrder.workState){
                case WorkOrderState.AwaitingDecision:
                    setSourceImg(WaitingImg);
                    setContainerCss({backgroundColor: WHITE, color: BLACK});
                    setContainerImgCss({backgroundColor: WHITE});
                    break;
                case WorkOrderState.EntryToSign:
                    setSourceImg(EntryToSignWhiteImg);
                    setContainerCss({backgroundColor: BLUE_PRIMARY, color: WHITE});
                    setContainerImgCss({backgroundColor: BLUE_SECONDARY});
                    break;
                case WorkOrderState.WIP:
                    setSourceImg(WipWhiteImg);
                    setContainerCss({backgroundColor: YELLOW_PRIMARY, color: WHITE});
                    setContainerImgCss({backgroundColor: YELLOW_SECONDARY});
                    break;
                case WorkOrderState.ExitToSign:
                    setSourceImg(ExitToSignWhiteImg);
                    setContainerCss({backgroundColor: BLUE_PRIMARY, color: WHITE});
                    setContainerImgCss({backgroundColor: BLUE_SECONDARY});
                    break;
                case WorkOrderState.UnfinishedWork:
                    setSourceImg(UnfinishedWorkYellowImg);
                    setContainerCss({backgroundColor: WHITE, color: YELLOW_PRIMARY});
                    setContainerImgCss({backgroundColor: WHITE});
                    break;
                case WorkOrderState.WorksToBeApproved:
                    setSourceImg(WorkToBeApprovedPurpleImg);
                    setContainerCss({backgroundColor: PURPLE_PRIMARY, color: WHITE});
                    setContainerImgCss({backgroundColor: PURPLE_SECONDARY});
                    break;
                case WorkOrderState.WorksApproved:
                    setSourceImg(CheckGreenImg);
                    setContainerCss({backgroundColor: WHITE, color: GREEN_PRIMARY});
                    setContainerImgCss({backgroundColor: WHITE});
                    break;
                case WorkOrderState.ExitToSignAndWorksToBeApproved:
                    setSourceImg(ExitToSignWorkToBeApprovedPurpleBlueImg);
                    setContainerCss({backgroundColor: WHITE, color: PURPLE_BLUE_PRIMARY});
                    setContainerImgCss({backgroundColor: WHITE});
                    break;
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Link className='woelement-main' to={route}>
            <div className='woelement-container' style={containerCss}>
                <p className='woelement-title'>{title}</p>
                <p className='woelement-status'>{description}</p>
                <div className='woelement-footer'>
                    <p className='woelement-num-work'>{number}</p>
                    <p className='woelement-building'>{workOrder.workBuildingName}</p>
                </div>
            </div>
            <div className='woelement-icon-container' style={containerImgCss}>
                <img alt='statut' className='woelement-icon' src={sourceImg} />
            </div>
        </Link>
    );
}

export default WOElement;