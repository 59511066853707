import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { EnterPin, Home, Login, OnePhoneSignature, ScanWorkOrder, SignatureState, WorkOrderDetail, WorkOrderDetailReadOnly, WorkOrders } from './pages';
import { useConnectWithCookies, usePlaceholder } from './hooks';
import { AppTools, ConnectState, MessageViewType, Role, InputType } from './types.ts';
import { DisplayElement, LoadingView, MessageView, Placeholder, SelectInput, SelectRole } from './views';
import { MessageViewProps } from './views/placeholder/MessageView.tsx';
import { ONE_PHONE_SIGNATURE_ROUTE, SCAN_WORK_ORDER_ROUTE, SIGNATURE_STATE_ROUTE, UNIQUE_WORK_ORDER_ROUTE, WORK_ORDER_ROUTE } from './route.ts';

const App = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [preferedRole, setPreferedRole] = useState<Role | undefined>();
    const [isVisibleMessageView, setIsVisibleMessageView] = useState<boolean>(false);
    const [messageViewConfig, setMessageViewConfig] = useState<MessageViewProps>(
        {text:"#text", description:"#description", type: MessageViewType.Success, hideMessage: () => {setIsVisibleMessageView(false)}});

    const [state, tryConnect, isExistUserId, disconnect, cancelConnect, reloadTimerExpiration, checkConnect] = useConnectWithCookies(() => {onTokenExpire()});

    const [listDisplay, pushDisplay, popDisplay, clearDisplay] = usePlaceholder();

    // affiche un message prennant la page complète
    const showMessage = (type: MessageViewType, text: string, description?: string) => {
        const newConfig = messageViewConfig;
        newConfig.text = text;
        newConfig.description = description;
        newConfig.type = type;
        setMessageViewConfig(newConfig);
        setIsVisibleMessageView(true);
    }

    // fonction appelé quand le token expire
    const onTokenExpire = () => {
        showMessage(MessageViewType.Warning, "Déconnexion pour cause d'inactivité");
        clearDisplay();
    }

    // fonction afin de vérifier si l'utilisateur est connecté
    const checkUserConnected = () => {
        checkConnect().then((res) => {
            if(!res){
                disconnect();
            }
        });
    }

    // affiche un selecteur permettant de choisir un role
    const displayRoleSelector = (roles: Role[], forceSelect?: boolean) : Promise<Role> => {
        return new Promise<Role>((resolve, reject) => {
            if(forceSelect !== true && preferedRole && roles.includes(preferedRole)){
                resolve(preferedRole);
            }else{
                pushDisplay(
                    <SelectRole 
                        roles={roles} 
                        onSelect={(role) => {
                            popDisplay();
                            setPreferedRole(role);
                            resolve(role);
                        }} 
                        onCancel={() => {
                            popDisplay();
                            reject();
                        }} />
                );
            }
        });
    }

    const displayInput = (title: string, placeholder: string, type: InputType, biggerInput?: boolean, selectOptions?: {value:any, label: string}[]): Promise<any> => {
        return new Promise((resolve, reject) => {
            pushDisplay(
                <SelectInput 
                    title={title} 
                    type={type}
                    selectOptions={selectOptions}
                    placeholder={placeholder} 
                    biggerInput={biggerInput}
                    onValidate={(value) => {
                        popDisplay();
                        if(type === InputType.number){
                            resolve(value as number);
                        }else{
                            resolve(value);
                        }
                    }} 
                    onCancel={() => {
                        popDisplay();
                        reject();
                    }} />
            );
        });
    }

    const displayText = (title: string, text: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            pushDisplay(
                <DisplayElement
                    title={title}
                    text={text}
                    onOk={() => {
                        popDisplay();
                        resolve();
                    }} />
            )
        });
    }; 

    // outils de l'application
    const tools: AppTools = {
        showMessage: showMessage,
        isLoading: (visible: boolean) => {setIsLoading(visible)},
        disconnect: disconnect,
        reloadTimerExpiration: reloadTimerExpiration,
        pushDisplay: pushDisplay,
        popDisplay: popDisplay,
        checkUserConnected: checkUserConnected,
        displayRoleSelector: displayRoleSelector,
        displayInput: displayInput,
        displayText: displayText
    };

    return (
        <>
            {state === ConnectState.NotConnected && (
                <BrowserRouter>
                    <Routes>
                        <Route path='*' element={<Login appTools={tools} isExistUserId={isExistUserId} />} />
                        <Route path={UNIQUE_WORK_ORDER_ROUTE} element={<WorkOrderDetailReadOnly appTools={tools} />} />
                    </Routes>
                </BrowserRouter>
            )}
            {state === ConnectState.HaveUserId && (
                <BrowserRouter>
                    <Routes>
                        <Route path='*' element={<EnterPin appTools={tools} tryConnect={tryConnect} cancelConnect={cancelConnect} />} />
                        <Route path={UNIQUE_WORK_ORDER_ROUTE} element={<WorkOrderDetailReadOnly appTools={tools} />} />
                    </Routes>
                </BrowserRouter>
            )}
            {state === ConnectState.Connected && (
                <BrowserRouter>
                    <Routes>
                        <Route path='*' element={<Home appTools={tools} />} />
                        <Route path={WORK_ORDER_ROUTE} element={<WorkOrders appTools={tools} />} />
                        <Route path={UNIQUE_WORK_ORDER_ROUTE} element={<WorkOrderDetail appTools={tools} />} />
                        <Route path={SIGNATURE_STATE_ROUTE} element={<SignatureState appTools={tools} />} />
                        <Route path={SCAN_WORK_ORDER_ROUTE} element={<ScanWorkOrder appTools={tools} />} />
                        <Route path={ONE_PHONE_SIGNATURE_ROUTE} element={<OnePhoneSignature appTools={tools} />} />
                    </Routes>
                </BrowserRouter>
            )}

            {listDisplay.map((display, index) => {
                return (
                    <Placeholder display={display} key={index} index={index} />
                )
            })}

            <div className='container-outside'>
                {isLoading && (
                    <div className='loading-container'>
                        <LoadingView />
                    </div>
                )}
                {isVisibleMessageView && (
                    <MessageView 
                        hideMessage={messageViewConfig!.hideMessage}
                        type={messageViewConfig!.type} 
                        text={messageViewConfig!.text}
                        description={messageViewConfig!.description} />
                )}
            </div>
        </>
        
    );
}

export default App;